import React from "react";
import styled from "styled-components";
import { CONSTANTS } from "../constants";
import { SectionHeading, Wrapper } from "./shared";
import KitchenIcon from "../../static/icons/house.svg";
import GardenIcon from "../../static/icons/bricks.svg";
import PaintIcon from "../../static/icons/paint-roller.svg";
import ApplianceIcon from "../../static/icons/drill.svg";
import FloorIcon from "../../static/icons/hammer.svg";
import ToolIcon from "../../static/icons/wrench.svg";

export default function Services() {
  return (
    <Wrapper>
      <SectionHeading>Services</SectionHeading>
      <ServicesList>
        <ServiceItem>
          <Icon>
            <KitchenIcon />
          </Icon>
          <TextWrapper>
            <Headline>Kitchen and bathroom installation</Headline>
            <Subtitle>
              My speciality ranges from complete renovation and redesign to
              making improvements to your space while working around existing
              appliances.
            </Subtitle>
          </TextWrapper>
        </ServiceItem>
        <ServiceItem>
          <Icon>
            <GardenIcon />
          </Icon>
          <TextWrapper>
            <Headline>Garden</Headline>
            <Subtitle>
              If you're planning to give your outdoor space a facelift, I offer
              fencing, landscaping with paving slabs, decking boards, artificial
              and natural grass.
            </Subtitle>
          </TextWrapper>
        </ServiceItem>
        <ServiceItem>
          <Icon>
            <PaintIcon />
          </Icon>
          <TextWrapper>
            <Headline>Painting and decorating</Headline>
            <Subtitle>
              Whether it is painting full rooms or creating a feature wall,
              replastering, hanging wallpaper, repairing water damage, I handle
              everything you need to get your room the perfect finish.
            </Subtitle>
          </TextWrapper>
        </ServiceItem>
        <ServiceItem>
          <Icon>
            <ApplianceIcon />
          </Icon>
          <TextWrapper>
            <Headline>Appliance installation</Headline>
            <Subtitle>
              I can help you install your brand new washing machine, tumble
              dryer, stove, oven, fridge freezer, dishwasher and more.
            </Subtitle>
          </TextWrapper>
        </ServiceItem>
        <ServiceItem>
          <Icon>
            <FloorIcon />
          </Icon>
          <TextWrapper>
            <Headline>Flooring</Headline>
            <Subtitle>
              I have years of experience installing hardwood, laminate, ceramic,
              porcelain and marble flooring with a professional finish.
            </Subtitle>
          </TextWrapper>
        </ServiceItem>
        <ServiceItem>
          <Icon>
            <ToolIcon />
          </Icon>
          <TextWrapper>
            <Headline>...and more</Headline>
            <Subtitle>
              If you're not sure if your job is covered, please don't hesitate
              to get in touch for a no-obligation quote.
            </Subtitle>
          </TextWrapper>
        </ServiceItem>
      </ServicesList>
    </Wrapper>
  );
}

const ServicesList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: ${CONSTANTS.space[7]};
  padding: 0;
`;

const ServiceItem = styled.li`
  display: flex;
  list-style: none;
`;

const Icon = styled.div`
  flex: 1;
  margin-right: ${CONSTANTS.space[3]};
  padding-top: ${CONSTANTS.space[0]};
`;

const TextWrapper = styled.div`
  flex: 14;
`;

const Headline = styled.p`
  margin-top: 0;
  margin-bottom: ${CONSTANTS.space[1]};
  font-size: ${CONSTANTS.font.size.medium};
  font-weight: ${CONSTANTS.font.weight.bold};

  @media (min-width: 720px) {
    font-weight: ${CONSTANTS.font.weight.semibold};
  }
`;

const Subtitle = styled.p`
  margin: 0;
`;
