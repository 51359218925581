import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { CONSTANTS } from "../constants";
import { buttonStyles, SectionHeading, Wrapper } from "./shared";

export default function GalleryPreview({ photos }) {
  return (
    <PreviewWrapper>
      <SectionHeading>Gallery</SectionHeading>
      <PhotoContainer>
        {photos.map((photo) => (
          <Image
            key={photo.number}
            src={photo.image[0].thumbnails.large.url}
            alt={photo.caption || ""}
            loading="lazy"
          />
        ))}
      </PhotoContainer>
      <GalleryLink to="/gallery">See more photos</GalleryLink>
    </PreviewWrapper>
  );
}

const PreviewWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`;

const PhotoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: ${CONSTANTS.space[4]};
  align-items: stretch;
  margin: ${CONSTANTS.space[3]} 0;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const GalleryLink = styled(Link)`
  ${buttonStyles}
  text-decoration: none;
  align-self: flex-end;
`;
