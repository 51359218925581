import Img from "gatsby-image";
import React from "react";
import styled from "styled-components";
import { CONSTANTS } from "../constants";

export default function Spotlight({ image }) {
  return (
    <Wrapper>
      <ImageWrapper>
        <SpotlightImage fluid={image.fluid}></SpotlightImage>
      </ImageWrapper>
      <About>
        Based in East London, I am a reliable, trustworthy and efficient kitchen
        and bathroom specialist who has been serving the community for over 20
        years.
      </About>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40vh;

  /* extra margin to push next section down  */
  margin-bottom: calc(${CONSTANTS.space[3]} + 100px);

  @media (min-width: 1000px) {
    height: 55vh;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: gainsboro;
`;

const SpotlightImage = styled(Img)`
  height: 100%;
  width: 100%;

  /* negative margin to override margin on main container
  */
  margin: 0 -${CONSTANTS.space[4]};
  padding: 0 ${CONSTANTS.space[4]};
`;

const offset = 40;

const About = styled.div`
  position: relative;
  bottom: 40px;
  padding: ${CONSTANTS.space[3]};
  color: ${CONSTANTS.color.textInverse};
  background-color: ${CONSTANTS.color.main};
  font-size: ${CONSTANTS.font.size.body};

  @media (min-width: 375px) {
    font-size: ${CONSTANTS.font.size.medium};
  }

  @media (min-width: 720px) {
    font-size: ${CONSTANTS.font.size.large};
    padding: ${CONSTANTS.space[5]};
  }

  @media (min-width: 1000px) {
    position: absolute;
    right: -${offset}px;
    bottom: -80px;
    left: ${offset * 3}px;
  }
`;
