import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Spotlight from "../components/Spotlight";
import Services from "../components/Services";
import GalleryPreview from "../components/GalleryPreview";
import Contact from "../components/Contact";
// import Reviews from "../components/Reviews";

export default function Index({ data }) {
  const photos = data.allAirtable.nodes.map((node) => node.data);
  const spotlightImage = data.file.childImageSharp;

  return (
    <Layout>
      <Spotlight image={spotlightImage} />
      <Services />
      <GalleryPreview photos={photos} />
      {/* <Reviews /> */}
      <Contact />
    </Layout>
  );
}

export const pageQuery = graphql`
  query GalleryPreviewQuery {
    allAirtable(limit: 3, sort: { fields: data___number }) {
      nodes {
        data {
          caption
          number
          image {
            thumbnails {
              large {
                url
              }
            }
          }
        }
      }
    }
    file(relativePath: { regex: "/spotlight_image2.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
      }
    }
  }
`;
